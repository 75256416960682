<template>
  <div class="login__wrap">
    <div class="login__main">
      <div style="display:flex;flex:1;">
        <div class="login-top">
          <el-image class="logoImg" :src="require('@/assets/img/logo-title.png')"></el-image>
          <div class="login__box">
            <p class="login-title">用户登录</p>
            <el-form
              class="sign__main"
              ref="elForm"
              :model="form.data"
              :rules="form.rules"
              @submit.prevent="submit"
            >
              <el-form-item prop="account">
                <el-input v-model.trim="form.data.account" clearable placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model.trim="form.data.password" clearable show-password placeholder="请输入密码" prefix-icon="el-icon-key"></el-input>
              </el-form-item>
              <el-checkbox v-model="form.data.checked">记住密码</el-checkbox>
              <el-form-item style="margin-top: 50px;">
                <el-button style="width: 100%;padding: 15px 40px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="foot">-江西金手指科技有限公司技术支持-</div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        data: {
          // account: '18979158400',
          // password: '123456',
          account: '',
          password: '',
          checked: '',
        },
        rules: {
          account: [{ required: true, message: '请输入用户名', trigger:'blur' }],
          password: [{ required: true, message: '请输入密码', trigger:'blur' }],
        }
      },
    }
  },
  methods: {
     submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          this.form.data.checked = this.form.data.checked === true ? 1 : 0
          this.$http.post('/admin/admin/login', this.form.data).then(res => {
            if(res.code === 1) {
              setStorage('token', res.data.token)
              setStorage('userName',res.data.name)
              this.$store.commit('setIsLogin', true)
              
              setStorage('icId', res.data.invoice_company_id)
              setStorage('role_type', res.data.type)
              setStorage('access', res.data.access)
              this.$router.push('/company/index')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
.login__wrap {
  width: 100%;
  height: 100vh;
  background-image: url('~@/assets/img/bg.png');
  background-size:cover;
  .login__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    .login-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .login-title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #333;
        margin-top: 0;
      }

      .logoImg {
        width: 500px;
      }
    }

    .login__box {
      width: 400px;
      padding: 40px;
      border-radius: 10px;
      background-color: #FFF;
      box-sizing: border-box;
      overflow: hidden;
      margin-top: 60px;
    }

    .foot {
      font-size: 20px;
      color: #fff;
      margin-bottom: 32px;
    }
    // @include position($t: 50%, $l: 50%);
    // transform: translate(-50%, -50%);
    .el-button--primary {
        background-color: $theme;
        border-color: $theme;
    }
  }
}
</style>
